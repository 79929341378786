import { Dispensary, DispensaryTagsEnum } from "custom-types/Dispensary";
import { useSelector } from "redux/hooks";
import { getRecreationalAlternateTerm } from "redux/selectors/complianceRules";

import Tag from "components/botanic/Tag";

const DispensaryFeatures: React.FC<{ dispensary: Dispensary }> = ({
  dispensary,
}) => {
  const {
    hasAtm,
    isUfcwMember,
    isAdaAccessible,
    hasVeteranDiscount,
    clinicWalkInsWelcome,
    verifiesMedicalCards247,
    offersWalletSizedMedicalCards,
    offersMedicalCards,
    tags = [],

    // some tags are also included in flags
    flags = [],
  } = dispensary;

  const recreationalTermOverride = useSelector(getRecreationalAlternateTerm);

  // It's unclear which of these canadaLp tag casings is correct, so checking for both
  const isLicensedProducer = tags.includes(DispensaryTagsEnum.CanadaLp);
  const isMedical = tags.includes(DispensaryTagsEnum.Medical);
  const isRec = tags.includes(DispensaryTagsEnum.Recreational);
  const isDelivery = tags.includes(DispensaryTagsEnum.Delivery);
  const isAzLicensed = tags.includes(DispensaryTagsEnum.AzLicensed);
  const isComingSoon = tags.includes(DispensaryTagsEnum.ComingSoon);
  const isMedDelivery =
    tags.includes(DispensaryTagsEnum.Delivery) &&
    tags.includes(DispensaryTagsEnum.Medical);
  const isStorefront = tags.includes(DispensaryTagsEnum.Storefront);

  const isArabMiddleEasternOwned = flags.includes("arabMiddleEasternOwned");
  const isAsianOwned = flags.includes("asianOwned");
  const isBlackOwned = flags.includes("blackOwned");
  const isDisabilityOwned = flags.includes("disabilityOwned");
  const isIndigenousOwned = flags.includes("indigenousOwned");
  const isLatinxOwned = flags.includes("latinxOwned");
  const isLGBTQIAOwned = flags.includes("lgbtqiaOwned");
  const isPacificIslanderOwned = flags.includes("pacificIslanderOwned");
  const isVeteranOwned = flags.includes("veteranOwned");
  const isWomanOwned = flags.includes("womanOwned");

  const featuresCollection: [boolean, string][] = [
    [hasAtm, "hasAtm"],
    [isStorefront, "isStorefront"],
    [isUfcwMember, "isUfcwMember"],
    [isAdaAccessible, "isAdaAccessible"],
    [hasVeteranDiscount, "hasVeteranDiscount"],
    [clinicWalkInsWelcome, "clinicWalkInsWelcome"],
    [verifiesMedicalCards247, "verifiesMedicalCards247"],
    [offersWalletSizedMedicalCards, "offersWalletSizedMedicalCards"],
    [offersMedicalCards, "offersMedicalCards"],
  ];

  const tagsCollection: [boolean, string][] = [
    [isLicensedProducer, "isLicensedProducer"],
    [isMedical, "isMedical"],
    [isRec, "isRec"],
    [isDelivery, "isDelivery"],
    [isMedDelivery, "isMedDelivery"],
    [isAzLicensed, "isAzLicensed"],
    [isComingSoon, "isComingSoon"],
  ];

  const identityAttributesCollection: [boolean, string][] = [
    [isArabMiddleEasternOwned, "isArabMiddleEasternOwned"],
    [isAsianOwned, "isAsianOwned"],
    [isBlackOwned, "isBlackOwned"],
    [isDisabilityOwned, "isDisabilityOwned"],
    [isIndigenousOwned, "isIndigenousOwned"],
    [isLatinxOwned, "isLatinxOwned"],
    [isLGBTQIAOwned, "isLGBTQIAOwned"],
    [isPacificIslanderOwned, "isPacificIslanderOwned"],
    [isVeteranOwned, "isVeteranOwned"],
    [isWomanOwned, "isWomanOwned"],
  ];

  const featuresMap: { [key: string]: string } = {
    clinicWalkInsWelcome: "Walk-ins welcome",
    hasAtm: "ATM",
    hasVeteranDiscount: "Veteran discount",
    isAdaAccessible: "ADA accessible",
    isStorefront: "Storefront",
    isUfcwMember: "UFCW discount",
    offersMedicalCards: "Offers physical cards",
    offersWalletSizedMedicalCards: "Wallet-sized authorizations",
    verifiesMedicalCards247: "24/7 verification",
  };

  const tagsMap: { [key: string]: string } = {
    isAzLicensed: "AZ licensed",
    isComingSoon: "Coming soon",
    isDelivery: "Delivery",
    isLicensedProducer: "Canada lp",
    isMedDelivery: "Medical delivery",
    isMedical: "Medical",
    isRec: recreationalTermOverride ? recreationalTermOverride : "Recreational",
  };

  const identityAttributesMap: { [key: string]: string } = {
    isArabMiddleEasternOwned: "Arab/Middle Eastern owned",
    isAsianOwned: "Asian owned",
    isBlackOwned: "Black owned",
    isDisabilityOwned: "Disability owned",
    isIndigenousOwned: "Indigenous owned",
    isLGBTQIAOwned: "LGBTQIA owned",
    isLatinxOwned: "Latinx owned",
    isPacificIslanderOwned: "Pacific Islander owned",
    isVeteranOwned: "Veteran owned",
    isWomanOwned: "Woman owned",
  };

  function getDispensaryFeatures() {
    return featuresCollection.map((feature) => {
      return (
        feature[0] &&
        featuresMap[feature[1]] && (
          <Tag
            className="mr-xs mb-xs bg-leafly-white border-none"
            key={feature[1]}
          >
            {featuresMap[feature[1]]}
          </Tag>
        )
      );
    });
  }

  function getDispensaryTags() {
    return tagsCollection.map((tag) => {
      return (
        tag[0] && (
          <Tag className="mr-xs mb-xs bg-leafly-white border-none" key={tag[1]}>
            {tagsMap[tag[1]]}
          </Tag>
        )
      );
    });
  }

  function getDispensaryIdentityAttributes() {
    return identityAttributesCollection.map((attribute) => {
      return (
        attribute[0] && (
          <Tag
            className="mr-xs mb-xs bg-leafly-white border-none"
            key={attribute[1]}
          >
            {identityAttributesMap[attribute[1]]}
          </Tag>
        )
      );
    });
  }
  return (
    <div>
      {getDispensaryFeatures()}
      {getDispensaryTags()}
      {getDispensaryIdentityAttributes()}
    </div>
  );
};

export default DispensaryFeatures;
