import React, { useState } from "react";

import deleteReviewVote from "api/requests/deleteReviewVote";
import postFlagReview from "api/requests/postFlagReview";
import postReviewVote from "api/requests/postReviewVote";
import { RetailType } from "custom-types/Dispensary";
import { DispensaryAddress } from "custom-types/DispensaryAddress";
import {
  DispensaryReview,
  ProductReview,
  StrainReview,
} from "custom-types/Reviews";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useSelector } from "redux/hooks";
import { getIsLoggedIn } from "redux/selectors/user";
import { getRetailerInfo } from "utils/getRetailerInfo";
import { retailCopyFormatter } from "utils/retailCopyFormatter";
import { trackReviewsEvent } from "utils/trackEvent";

import ReviewCard from "components/botanic/ReviewCard";

export enum ReviewType {
  DISPENSARY = "dispensary",
  PRODUCT = "product",
  STRAIN = "strain",
}

const ReviewContainer: React.FC<{
  hideMetadata?: boolean;
  retailType?: string;
  review: DispensaryReview | StrainReview | ProductReview;
  type?: ReviewType;
  itemReviewed?: string;
  dispensaryAddress?: DispensaryAddress;
}> = ({
  hideMetadata,
  retailType,
  itemReviewed,
  review,
  type = ReviewType.DISPENSARY,
  dispensaryAddress,
}) => {
  const countryCode = useDomainCountryCode();

  const isLoggedIn = useSelector(getIsLoggedIn);

  const { hasCurrentUserFlagged, hasCurrentUserUpvoted } = review as
    | DispensaryReview
    | ProductReview;
  const [hasReported, setHasReported] = useState(hasCurrentUserFlagged);
  const [hasUpvoted, setHasUpvoted] = useState(hasCurrentUserUpvoted);

  const { upvotesCount } = review as DispensaryReview | StrainReview;
  const { upvoteCount: productUpvoteCount } = review as ProductReview;
  const [upvoteCount, setUpvoteCount] = useState(
    upvotesCount || productUpvoteCount,
  );

  const addVote = async () => {
    const postReviewSuccess = await postReviewVote(review.id);

    if (postReviewSuccess) {
      setUpvoteCount(upvoteCount + 1);
      setHasUpvoted(true);
      trackReviewsEvent("Thumbs up", "Add");
    }
  };

  const deleteVote = async () => {
    const deleteReviewVoteSuccess = await deleteReviewVote(review.id);

    if (deleteReviewVoteSuccess) {
      setUpvoteCount(upvoteCount - 1);
      setHasUpvoted(false);
      trackReviewsEvent("Thumbs up", "Remove");
    }
  };

  const toggleReviewVote = async () => {
    if (isLoggedIn) {
      hasUpvoted ? deleteVote() : addVote();
    }
  };

  const flagReview = () => {
    postFlagReview(review.id, type).then((flagReviewSuccess) => {
      if (flagReviewSuccess) {
        setHasReported(true);
        trackReviewsEvent("Report abuse");
      }
    });
  };

  const handleReportAction = () => {
    if (isLoggedIn && !hasReported) {
      flagReview();
    }
  };

  const retailerCopy = retailCopyFormatter(
    getRetailerInfo(countryCode, retailType as RetailType).copy,
    "capitalize",
  );

  return (
    <>
      <div className="inline-block mb-lg w-full">
        <ReviewCard
          badges={(review as DispensaryReview).badges}
          created={review.created}
          hasReported={hasReported}
          hasUserVoted={hasUpvoted}
          hideMetadata={hideMetadata}
          itemReviewed={itemReviewed}
          rating={review.rating}
          referringPageType={type}
          reportAction={handleReportAction}
          reportedFeelings={(review as StrainReview).reportedFeelings}
          retailerName={retailerCopy}
          text={review.text}
          truncate={true}
          upvoteCount={upvoteCount}
          username={review.user.username}
          userPublicId={review.user.publicId}
          voteAction={toggleReviewVote}
          dispensaryAddress={dispensaryAddress}
        />
      </div>
    </>
  );
};

export default ReviewContainer;
